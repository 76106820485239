export default {
  formatTotal(total) {
    return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(total || 0)
  },

  formatDate(value) {
    if (!value) return value
    return new Intl.DateTimeFormat('en-MY').format(new Date(value))
  },

  formatDateTime(value) {
    if (!value) return value
    return new Intl.DateTimeFormat('en-MY', {
      hourCycle: 'h12',
      timeStyle: 'short',
      dateStyle: 'short',
    }).format(new Date(value)).replace(/,/g, '')
  },
}
