export default class UrlConfig {
  constructor(host) {
    this.host = host
  }

  getBaseUri() {
    let baseUrl = ''
    switch (this.host) {
      case 'smart.golog.my':
        baseUrl = 'https://smart.golog.my/'
        break
      case 'smartstaging.golog.my':
        baseUrl = 'https://smartstaging.golog.my/'
        break
      case 'smartdemo2.golog.my':
        baseUrl = 'https://smartdemo2.golog.my/'
        break
      default:
        baseUrl = 'https://smartstaging.golog.my/'
        break
    }
    return baseUrl
  }

  getApiUrl() {
    let appUrl = ''
    switch (this.host) {
      case 'smart.golog.my':
        appUrl = 'https://my-app-qx8gs.ondigitalocean.app/api/'
        break
      case 'smartstaging.golog.my':
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/api/'
        break
      case 'smartdemo2.golog.my':
        appUrl = 'https://my-app-g2quh.ondigitalocean.app/api/'
        break
      default:
        appUrl = 'https://b2b-laravel-staging-pwvly.ondigitalocean.app/api/'
        break
    }

    return appUrl
  }
}
