import axiosIns from "@/libs/axios";

let sessionCartItems = null;

export default {
  state: {
    cart: [],
  },
  mutations: {
    ADD_CART_ITEMS: (state, order) => {
      state.cart.push(order);
      sessionCartItems = null;
    },
    CLEAR_CART: (state) => {
      state.cart = [];
      sessionCartItems = null;
    },
    REMOVE_CART_ITEM: (state, id) => {
      const index = state.cart.findIndex((item) => item.id === id);
      state.cart.splice(index, 1);
      sessionCartItems = null;
    },
    SET_CART_ITEMS: (state, items) => {
      state.cart = items;
      sessionCartItems = items;
    },
  },
  getters: {
    getCartItems: (state) => {
      return state.cart;
    },
    getCartItemsLength: (state) => {
      return state.cart.length;
    },
  },
  actions: {
    async addToCart({ state, commit, dispatch }, { order }) {
      try {
        const checkItemIfExisting = state.cart.find(
          (item) => item.id === order.id
        );
        if (!checkItemIfExisting) {
          await axiosIns.post("cart", { order_id: order.id });
          commit("ADD_CART_ITEMS", order);
          await dispatch("fetchCartItems");
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async clearCart({ commit, dispatch }) {
      try {
        await axiosIns.delete("cart");
        commit("CLEAR_CART");
        await dispatch("fetchCartItems");
      } catch (e) {
        console.error(e);
      }
    },
    async removeCartItem({ commit, dispatch }, id) {
      try {
        await axiosIns.delete(`cart/${id}`);
        commit("REMOVE_CART_ITEM", id);
        await dispatch("fetchCartItems");
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCartItems({ commit }) {
      try {
        const response = await axiosIns.get("cart");
        commit("SET_CART_ITEMS", response.data);
      } catch (e) {
        console.error("Error fetching cart items:", e);
      }
    },
  },
};