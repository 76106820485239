import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import UrlConfig from '@/libs/config'
import i18n from '@/libs/i18n'
import utility from './common/utility'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Utility plugin
const plugin = {
  install() {
    Vue.helpers = utility
    Vue.prototype.$helpers = utility
  },
}

Vue.use(plugin)

Vue.use(VueSweetalert2)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// const appURL = process.env.BACKEND_URL
const APP_URL = window.location.host
const urlSetting = new UrlConfig(APP_URL)

Vue.prototype.$appURL = urlSetting.getApiUrl()
Vue.prototype.$s3URL = 'https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/'
Vue.prototype.$processingURL = 'https://processing.golog.my/'
Vue.prototype.$baseUrl = urlSetting.getBaseUri()
Vue.prototype.$pdfUrl = urlSetting.getApiUrl()
Vue.prototype.$regCheckHost = 'https://regcheck.org.uk/api/reg.asmx/CheckMalaysia?username=zipking1988&'
Vue.prototype.$status = {
  0: { name: 'Unpaid', color: '#868686' },
  1: { name: 'Available', color: '#1abc9c' },
  2: { name: 'Assigned', color: '#9b59b6' },
  3: { name: 'Arrive at sender', color: '#f1c40f' },
  4: { name: 'Arrive at Golog', color: '#700000' },
  5: { name: 'In Transit', color: '#3498db' },
  6: { name: 'Picked by Lorry', color: '#e67e22' },
  7: { name: 'Out for delivery', color: '#ff008c' },
  8: { name: 'Delivered', color: '#2ecc71' },
  9: { name: 'Requesting cancel', color: '#e74c3c' },
  10: { name: 'Cancelled', color: '#e74c3c' },
  11: { name: 'Requested For Refund', color: '#aba148' },
}
Vue.prototype.$driverStatus = {
  0: { variant: 'dark', name: 'Not Verified' },
  1: { variant: 'success', name: 'Verified' },
  99: { variant: 'danger', name: 'Banned' },
}
Vue.config.productionTip = false
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
