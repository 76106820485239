import i18n from "@/libs/i18n";

export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/pages/inside/MyDashboard.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("Dashboard"),
      breadcrumb: [
        {
          text: i18n.t("Dashboard"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/delivery-history",
    name: "DeliveryHistory",
    component: () => import("@/views/pages/inside/DeliveryHistory.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("Delivery History"),
      breadcrumb: [
        {
          text: i18n.t("previous_transaction"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/hypermarket-order",
    name: "hypermarket-order",
    component: () => import("@/views/pages/inside/HyperMarket.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("Orders"),
      breadcrumb: [
        {
          text: i18n.t("B2BPro Orders"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/fleet-forwarding",
    name: "fleet-forwarding",
    component: () =>
      import("@/views/pages/inside/FleetForward/FleetForwarding.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("order"),
      breadcrumb: [
        {
          text: i18n.t("Freight Forwarding"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/fleet-forwarding-summary/:id",
    name: "fleet-forwarding-summary",
    component: () =>
      import("@/views/pages/inside/FleetForward/OrderSummary.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("order"),
      breadcrumb: [
        {
          text: i18n.t("Freight Forwarding Summary"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/chartered-truck",
    name: "chartered-truck",
    component: () =>
      import("@/views/pages/inside/CharteredTruck/CharteredTruck.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("order"),
      breadcrumb: [
        {
          text: i18n.t("Chartered Truck"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/chartered-truck-summary/:id",
    name: "chartered-truck-summary",
    component: () =>
      import("@/views/pages/inside/CharteredTruck/OrderSummary.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("order"),
      breadcrumb: [
        {
          text: i18n.t("Chartered Truck Summary"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/view-po/:id/:date",
    name: "view-po",
    component: () => import("@/views/pages/inside/ViewPo.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("view_PO"),
      breadcrumb: [
        {
          text: i18n.t("view_PO"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/payouts/:orderId",
    name: "payouts",
    component: () => import("@/views/pages/inside/Payouts.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("payouts"),
      breadcrumb: [
        {
          text: i18n.t("payouts"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/deliveries",
    name: "MyDeliveries",
    component: () => import("@/views/pages/inside/MyDeliveries.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("my_deliveries"),
      breadcrumb: [
        {
          text: i18n.t("deliveries"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/cancellation-requests",
    name: "CancellationRequests",
    component: () => import("@/views/pages/inside/CancellationRequests.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("cancellation_requests"),
      breadcrumb: [
        {
          text: i18n.t("request_for_cancellation"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/pending-deliveries",
    name: "PendingDeliveries",
    component: () => import("@/views/pages/inside/PendingDeliveries.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("pending_deliveries"),
      breadcrumb: [
        {
          text: i18n.t("delivery_backlog"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/order-summary/:orderId",
    name: "OrderSummary",
    component: () => import("@/views/pages/inside/OrderSummary.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("order"),
      breadcrumb: [
        {
          text: i18n.t("order_summary"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/deliveryconfirmation",
    name: "deliveryconfirmation",
    component: () => import("@/views/pages/inside/Confirm.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("confirm"),
      breadcrumb: [
        {
          text: i18n.t("confirm_order"),
          active: true,
        },
      ],
    },
  },

  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("@/views/pages/inside/MyProfile.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("my_profile"),
      breadcrumb: [
        {
          text: i18n.t("my_profile"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/mycart",
    name: "mycart",
    component: () => import("@/views/pages/inside/MyCart.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("my_cart"),
      breadcrumb: [
        {
          text: i18n.t("my_cart"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/mydriver",
    name: "mydriver",
    component: () => import("@/views/pages/inside/MyDriver.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("my_drivers"),
      breadcrumb: [
        {
          text: i18n.t("my_drivers"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/myvehicle",
    name: "myvehicle",
    component: () => import("@/views/pages/inside/MyVehicle.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("my_vehicles"),
      breadcrumb: [
        {
          text: i18n.t("my_vehicles"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/track",
    name: "track",
    component: () => import("@/views/pages/inside/Track.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("track"),
      breadcrumb: [
        {
          text: i18n.t("trace"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/track-order/:deliveryId",
    name: "TrackOrder",
    component: () => import("@/views/pages/inside/TrackOrder.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("track_order"),
      breadcrumb: [
        {
          text: i18n.t("order"),
          active: true,
        },
      ],
    },
  },
  {
    path: "/delivery-tracking/:orderId",
    name: "DeliveryTracking",
    component: () => import("@/views/pages/inside/DeliveryTracking.vue"),
    meta: {
      group: 3,
      pageTitle: i18n.t("delivery_tracking"),
      breadcrumb: [
        {
          text: i18n.t("tracking"),
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('@/views/pages/inside/account-setting/AccountSetting.vue'),
  //   meta: {
  //     group: 3,
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
