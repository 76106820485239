export default{
    state : {
       status: 0,
       documents: 0
    },
    mutations : {
        set : (state, data) => {
            state.status = data.status
            state.documents = data.documents
        },

        reset: (state) => {
            state.status = 0
            state.documents = 0
        }
    },
    getters : {
        getStatus : state => state.status,

        getDocuments : state => state.documents

    }

}