import axios from '@axios'

export default {
  namespaced: true,
  state: {
    orders: [],
    isLoading: false,
    orderDetails: [],
  },
  mutations: {
    SET_ORDER_DETAILS(state, value) {
      state.orderDetails = value.data
    },
    SET_ORDERS(state, value) {
      state.orders = value.data
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
    SET_ORDERS_FAILURE(state) {
      state.isLoading = false
      state.orders = []
    },
    SET_ORDERS_TO_EMPTY(state) {
      state.orders = []
    },
  },
  getters: {
    orderDetails: state => state.orderDetails,
    checkOrders: state => state.orders,
    checkLoadingState: state => state.isLoading,
  },
  actions: {
    async fetchOrders({ commit }, { query = '' }) {
      try {
        commit('SET_ORDERS_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const { data } = await axios.get(`/orders?${query}`)
        commit('SET_ORDERS', data)
        commit('SET_IS_LOADING', false)
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
        console.error(e)
      }
    },
    async fetchOrder({ commit }, { id }) {
      try {
        commit('SET_ORDERS_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const { data } = await axios.get(`/orders/${id}`)
        commit('SET_ORDER_DETAILS', data)
        commit('SET_IS_LOADING', false)
      } catch (e) {
        commit('SET_ORDERS_FAILURE')
        console.error(e)
      }
    },
  },
}
